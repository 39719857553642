<template>
  <div class="app-container wrap">
    <div class="table-container">
      <div class="order-list-explain">
        订单查询说明：<br />
        A、默认订单显示最近3个月的订单数据，如果需要查看对应订单的数据的话，请筛选对应的时间来查询对应的订单数据（筛选的时间可以超过3个月）；<br />
        B、如果发现订单状态没有更新，可以刷新当前页面获取最新订单状态信息；<br />
        C、目前订单的待付款有效时间为60分钟，超过就自动关闭订单；
      </div>
      <form @submit.prevent="onSearch" class="filter-list">
        <div class="filter-item">
          <span class="label">订单编号/交易单号:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.orderNo"></el-input>
          </div>
        </div>
        <div class="filter-item">
          <span class="label">收货人手机号:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.consigneeMobile"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">下单时间:</span>
          <div class="content">
            <el-date-picker
              class="date-picker"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
              end-placeholder="结束日期"
              start-placeholder="开始日期"
              type="daterange"
              v-model="createDate"
            ></el-date-picker>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">买家订单编号:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.buyerOrderNo"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">收货人姓名:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.consigneeName"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">商品名称:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.commodityName"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">品牌:</span>
          <div class="content">
            <el-select
              clearable
              multiple
              filterable
              size="small"
              style="width: 100%"
              v-model="filter.brandIds"
            >
              <el-option
                :key="idx"
                :label="item.name"
                :loading="brandOptionsLoading"
                :value="item.id"
                v-for="(item, idx) in brandList"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">商品规格标识:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.skuId"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">订单来源:</span>
          <div class="content">
            <el-select clearable size="small" style="width: 100%" v-model="filter.orderSource">
              <el-option
                :key="idx"
                :label="item.label"
                :loading="sourceOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in sourceOptions"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">创建方式:</span>
          <div class="content">
            <el-select clearable size="small" style="width: 100%" v-model="filter.orderSaleType">
              <el-option
                :key="idx"
                :label="item.label"
                :loading="typeOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in typeOptions"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">订单状态:</span>
          <div class="content">
            <el-select
              clearable
              size="small"
              style="width: 100%"
              v-model="filter.statusList"
              multiple
            >
              <el-option
                :key="idx"
                :label="item.label"
                :loading="statusOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in statusOptions"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">订单合同主体:</span>
          <div class="content">
            <el-select
              clearable
              size="small"
              style="width: 100%"
              v-model="filter.distributorContractInfoIds"
              multiple
              placeholder="请选择（可多选）"
            >
              <el-option
                :key="idx"
                :label="item.label"
                :loading="orderOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in contractList"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div :class="{ 'btns-open': filterMore }" class="filter-item filter-item-btn">
          <el-button native-type="submit" size="small" type="primary">查询</el-button>
          <el-button @click="onReset" size="small">重置</el-button>
          <el-button
            :icon="filterMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            @click="filterMore = !filterMore"
            class="toggle-btn"
            size="small"
            type="text"
            >{{ filterMore ? '收起' : '展开' }}</el-button
          >
          <!-- <div style="flex: 1"></div> -->
          <el-button
            :loading="exportLoadingNormal"
            @click="onExport('normal')"
            type="primary"
            size="small"
            >普通导出</el-button
          >
          <el-button
            :loading="exportLoading"
            @click="onExport('dropshipping')"
            type="primary"
            size="small"
            >代发模板导出</el-button
          >
        </div>
      </form>
      <table border="0" cellpadding="0" cellspacing="0" class="refund-list">
        <thead class="table-head">
          <tr class="line">
            <th>
              <div class="cell">商品信息</div>
            </th>
            <th>
              <div class="cell">单价</div>
            </th>
            <th>
              <div class="cell">数量</div>
            </th>
            <th>
              <div class="cell">收货人/买家订单编号</div>
            </th>

            <th>
              <div class="cell">实收金额</div>
            </th>
            <th>
              <div class="cell">订单状态</div>
            </th>
            <th>
              <div class="cell">操作</div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body" v-for="(item, index) in list" :key="index">
          <tr class="line tips-container">
            <td :colspan="ENV_CONFIG !== 'prod' ? '11' : '10'" class="tips-content">
              <div class="cell">
                <span>{{ item.createDate | parseDefaultTime() }}</span>
                <span>
                  <span v-if="item.orderNo">订单编号：{{ item.orderNo }}</span>
                  <el-tag
                    size="mini"
                    hit
                    class="el-tag-copy"
                    v-clipboard:copy="item.orderNo"
                    v-clipboard:error="onError"
                    v-clipboard:success="onCopy"
                    >复制</el-tag
                  >
                  <span v-if="item.type === 'GLOBAL'">
                    {{ filterCustoms(customsMap, item) }}
                  </span>
                </span>
                <span>
                  <!-- <span v-if="item.orderNo && item.transactionNo">,</span> -->
                  <span v-if="item.transactionNo">交易单号：{{ item.transactionNo }}</span>
                </span>
                <el-tag
                  size="mini"
                  hit
                  class="el-tag-copy"
                  v-if="item.transactionNo"
                  v-clipboard:copy="item.transactionNo"
                  v-clipboard:error="onErrorTransactionNo"
                  v-clipboard:success="onCopyTransactionNo"
                  >复制</el-tag
                >
                <span class="zt-box">
                  <span>{{ item.typeName }}</span>
                  <span>/</span>
                  <span>{{ item.orderChannelName }}</span>
                  <span>/</span>
                  <span>{{ item.orderSaleTypeName }}</span>
                </span>
                <el-tag size="mini" hit class="el-tag-copy" v-if="item.contractInfo"
                  >合同主体:{{
                    item.contractInfo.merchantTypeName + '-' + item.contractInfo.contractInfoName
                  }}</el-tag
                >
                <span class="failure" v-if="item.orderGlobal && item.orderGlobal.isSamePay === '0'">
                  报关失败
                  <el-button @click="$refs.customs.show(item.id)" type="text">修改</el-button>
                </span>
                <span v-if="item.isInvoiced === '1'" class="invoiced">已开票</span>
              </div>
            </td>
          </tr>
          <tr class="line">
            <!-- 商品信息 -->
            <td class="goods-img goods-info">
              <div
                :key="idx"
                class="cell"
                :class="idx === item.orderDetailVOList.length - 1 ? '' : 'border-bottom'"
                v-for="(datas, idx) in item.orderDetailVOList"
              >
                <router-link
                  :to="
                    item.orderSource === 'YEARLY_RECOMMEND_PLAN'
                      ? ''
                      : '/syoung/commodity/goods-detail/' + datas.commodityId
                  "
                >
                  <div class="goods-info--cell">
                    <oss-image :src="datas.imgUrl" class="table-img" />
                    <div class="goods-info--cell--rightbox">
                      <div class="goods-info--cell--name">
                        {{ datas.commodityName }}
                      </div>
                      <div class="subhead" v-if="datas.commoditySpecValue">
                        规格:{{ datas.commoditySpecValue }}
                      </div>
                      <el-tag
                        v-if="datas.isBackVirtualCredit === '1'"
                        class="backVirtualCredit"
                        size="mini"
                        color="#FFE8E8"
                        >返利</el-tag
                      >
                      <el-tag v-if="datas.isGift === '1'" class="el-tag-gift" size="mini"
                        >赠品</el-tag
                      >
                    </div>
                  </div>
                </router-link>
              </div>
            </td>
            <!-- 单价 -->
            <td class="single">
              <div
                :key="idx"
                class="cell"
                :class="idx === item.orderDetailVOList.length - 1 ? '' : 'border-bottom'"
                v-for="(datas, idx) in item.orderDetailVOList"
              >
                <div class="t-box">¥{{ datas.price }}</div>
              </div>
            </td>
            <!-- 数量 -->
            <td class="single border-right">
              <div
                :key="idx"
                class="cell"
                :class="idx === item.orderDetailVOList.length - 1 ? '' : 'border-bottom'"
                v-for="(datas, idx) in item.orderDetailVOList"
              >
                <div class="t-box">{{ datas.quantity }}</div>
              </div>
            </td>
            <!--  收货人/买家订单编号 -->
            <td class="goods-info border-right">
              <div class="cell">
                <div>
                  {{ item.orderConsigneeAddressVO.consignee }},{{
                    item.orderConsigneeAddressVO.mobile
                  }}
                </div>
                <div v-if="item.buyerOrderNo">{{ item.buyerOrderNo }}</div>
              </div>
            </td>
            <!--  实收金额 -->
            <td class="border-right">
              <div class="cell">
                {{ item.receiptAmount }}
              </div>
            </td>
            <!--  订单状态 -->
            <td class="border-right">
              <div class="cell">
                <div>{{ item.statusName }}</div>
                <div class="time-clock" v-if="item.status === 'WAIT_PAY'">
                  剩余:
                  <TimeClock
                    :date="item.expireTime"
                    :ref="'time_' + item.id"
                    :showMillisecond="false"
                  ></TimeClock>
                </div>
              </div>
            </td>
            <td class="border-right">
              <div class="cell">
                <CheckDeliveryDetail
                  :id="item.orderId || item.id"
                  v-if="
                    item.status === 'DELIVERED' ||
                    item.status === 'SUB_DELIVERY' ||
                    item.status === 'RECEIVED' ||
                    item.status === 'FINISHED' ||
                    item.status === 'REFUND'
                  "
                >
                  <!-- primary -->
                  <el-button size="mini" :type="isPrimaryBtn(item, 'wl')" class="order-list--btn">
                    查看物流
                  </el-button>
                </CheckDeliveryDetail>
                <el-button
                  @click="linkPay(item)"
                  size="mini"
                  :type="isPrimaryBtn(item, 'fk')"
                  class="order-list--btn"
                  v-if="item.status === 'WAIT_PAY'"
                >
                  付款
                </el-button>
                <el-button
                  @click="buyAgain(item)"
                  size="mini"
                  :type="isPrimaryBtn(item, 'gwc')"
                  class="order-list--btn add-car-btn"
                  v-if="
                    item.status !== 'WAIT_PAY' &&
                    item.orderSource !== 'SAMPLE' &&
                    item.orderSource !== 'SPECIAL_BUY' &&
                    item.orderSource !== 'CREDIT_EXCHANGE' &&
                    item.orderSource !== 'YEARLY_RECOMMEND_PLAN'
                  "
                  >加入购物车</el-button
                >
                <CancelOrder
                  btnType="text"
                  @cancelSuccess="cancelSuccess"
                  :id="item.id"
                  v-if="item.status === 'WAIT_PAY'"
                ></CancelOrder>
                <div>
                  <router-link :to="'/syoung/order/inquiry/detail/' + item.id" class="link linknone"
                    >订单详情</router-link
                  >
                </div>
              </div>
            </td>
          </tr>
          <div class="line-bottom-border"></div>
        </tbody>
      </table>
      <div class="empty" v-if="list.length === 0">暂无数据</div>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :disabled="listLoading"
          :page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <PayCode
      :postData="postData"
      @changePayType="changePayType"
      @sureRefresh="sureRefresh"
      ref="pay"
    ></PayCode>
    <MemberManagement @sureRefresh="sureRefresh" id="afresh-edit" ref="customs">
      <span></span>
    </MemberManagement>
  </div>
</template>
<script>
import {
  list,
  fetchOrderStatusOptions,
  fetchOrderTypeOptions,
  fetchTypeOptions,
  fetchLogisticsOptions,
  deliveryGoodsByHand,
  refund,
  addServiceNotes,
  exportTable,
  exportDropshipping,
  fetchOrderSourceOptions,
  addToCartInBatch,
  listOrderContractInfo,
} from '@/api/order/inquiry/list';
import TimeClock from '@/components/TimeClock';
import { listCustoms } from '@/api/common/dict';
import CheckDeliveryDetail from '@/components/CheckDeliveryDetail';
import pick from 'lodash/pick';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
// import Amend from './Amend';
import download from '@/utils/download';
import { parseTime } from '@/utils';
import MemberManagement from '@/components/views/checkout/MemberManagement'; // 报关
import PayCode from '@/components/Pay/PayCode';
import CancelOrder from '@/components/CancelOrder';
import { mapMutations } from 'vuex';
import eventReporter from '@/utils/event-reporter';
import { listAll as brandListAll } from '@/api/setting/commodity/brand';
export default {
  components: {
    CheckDeliveryDetail,
    TimeClock,
    MemberManagement,
    PayCode,
    CancelOrder,
    // Amend
  },
  data() {
    const initFilter = {
      orderNo: '', // 订单编号
      distributorId: '', // 分销商id
      buyerOrderNo: '', // 买家订单号
      buyerMobile: '', // 买家手机号
      consigneeName: '', // 收货人姓名
      consigneeMobile: '', // 收货人手机号
      commodityName: '', // 商品名
      brandIds: [], // 品牌
      skuId: '', // 商品规格编码
      orderSaleType: '', // 订单创建方式
      statusList: [], // 订单状态
      orderChannel: '', // 来源渠道
      orderSource: '', // 订单来源
      type: '', // 订单类型
      distributorContractInfoIds: [], // 合同主体
    };
    return {
      postData: {}, // 扫码充值传参
      dialogVisible: false, // 修改客服备注的弹窗
      serviceNotesId: null, // 需要修改的当前的订单备注的id
      serviceNotes: '', // 客服备注
      ENV_CONFIG: process.env.VUE_APP_ENV_CONFIG,
      filterMore: true,
      createDate: this.initDate(), // 下单时间，由申请开始时间和申请结束时间组成 默认最近3个月
      initFilter,
      filter: cloneDeep(initFilter),
      typeOptions: [], // 创建方式列表
      statusOptions: [], // 订单状态列表
      contractList: [], // 订单合同主体列表
      sourceOptionsLoading: false, // 订单来源是否在加载
      sourceOptions: [], // 订单来源选项
      orderTypeOptions: [], // 订单类型列表
      orderTypeOptionsLoading: false, // 订单类型列表Loading
      list: [],
      typeOptionsLoading: true,
      statusOptionsLoading: true,
      orderOptionsLoading: false,
      listLoading: true,
      logisticsOptionsLoading: true,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      dialogFormVisible: false, // 弹出框的显示控制
      currentRow: 0, // 当前选择的点击发货的行数
      currentOrderNos: '', // 当前选择的订单编号
      orderConsigneeAddressVO: {}, // 当前弹出框的订单收货信息
      logisticsOptions: [], // 当前弹出框的选择物流公司的下拉框
      commodityForm: {
        logistics: '', // 弹出框中选择的物流公司
        deliveryNo: '', // 弹出框中输入的快递单号
      },
      rules: {
        logistics: [{ required: true, message: '请选择的物流公司', trigger: 'change' }],
        deliveryNo: [{ required: true, message: '请输入快递单号', trigger: 'blur' }],
      },
      tableData: [], // 当前弹窗展示的订单中商品信息
      btnLoading: false,
      refundLoading: false,
      orderBy: 'create_date desc',
      customsMap: {},
      dialogAmendVisible: false, // 修改价格弹出框
      AmendData: {}, // 修改价格弹出框信息
      exportLoading: false,
      exportLoadingNormal: false,
      payType: this.getPayType(), // 支付方式，默认是微信支付,如果用户有切换支付方式则选择用户的支付方式
      rowData: {}, // 点击的的行数据
      brandOptionsLoading: false, // 品牌列表Loading
      brandList: [], // 品牌列表
    };
  },
  computed: {
    // 过滤
    data() {
      return pickBy(this.filter, val => !!val);
    },
  },
  mounted() {
    this.fetchData();
    this.fetchBrandListAll();
    this.fetchStatus();
    this.getOrderList();
    this.fetchType();
    this.fetchOrderSource();
    this.fetchOrderType();
    listCustoms().then(res => {
      const map = {};
      res.data.forEach(({ label, value }) => {
        map[value] = label;
      });
      this.customsMap = Object.freeze(map);
    });
  },
  methods: {
    // 取消订单之后刷新数据
    cancelSuccess() {
      this.fetchData();
    },
    ...mapMutations(['UPDATE_SET_CARNUMBER']),
    initDate() {
      const start = new Date(new Date(new Date().toLocaleDateString()).getTime());
      const end = new Date(
        new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
      );
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
    // 再次购买，批量加入购物车,赠品不加购
    async buyAgain(datas) {
      const requestData = datas.orderDetailVOList
        .filter(item => {
          return item.isGift === '0';
        })
        .map(item => {
          if (item.isGift === '0') {
            return {
              commodityId: item.commodityId,
              skuId: item.skuId,
              quantity: item.quantity,
            };
          }
        });
      addToCartInBatch(requestData).then(res => {
        // this.UPDATE_SET_CARNUMBER(quantity);
        if (res.code === '0') {
          this.$message({
            message: '成功加入采购车',
            type: 'success',
          });
          // 加入购物车埋点
          eventReporter.trackClick(this.$route, {
            name: 'zg_add_goods_click_btn',
            aliasName: '加入购物车',
            query: requestData,
            event_source: 'oder_detail',
          });
          this.$router.push('/syoung/car/list');
          return;
        }
        this.$message({
          message: res.msg,
          type: 'error',
        });
      });
    },
    // 获取支付方式，默认是微信支付,如果用户有切换支付方式则选择用户的支付方式
    getPayType() {
      if (localStorage.getItem('otherPay')) {
        return localStorage.getItem('otherPay') === '微信' ? 'weChat' : 'alipay';
      }
      return 'weChat';
    },
    // 切换支付方式
    changePayType(value) {
      this.payType = value === '微信' ? 'weChat' : 'alipay';
      this.linkPay(this.rowData);
    },
    getGoodsCount(list) {
      let num = 0;
      list.forEach(item => {
        num = item.quantity + num;
      });
      return num;
    },
    linkPay(item) {
      this.rowData = item;
      if (this.$refs['time_' + item.id][0].isStop) {
        this.$message.warning('已超出支付时间，请重新下单');
        return;
      }
      this.postData = {
        payBizCode: item.payBizCode,
        bizType: item.bizType,
        body: '水羊直供',
        id: item.id,
        payAmount: item.payAmount,
        expireTime: item.expireTime,
        customerTel: item.buyerMobile,
        customerIdNo: item.orderGlobal ? item.orderGlobal.idcardNo : '',
        customerName: item.orderGlobal ? item.orderGlobal.buyerName : '',
        goodsCount: this.getGoodsCount(item.orderDetailVOList),
        type: item.type,
        ownCompanyId: item?.ownCompanyId || '',
        brandType: item?.brandType || '',
      };
      sessionStorage.setItem('payData', JSON.stringify(this.postData));
      window.open(`/syoung/pay/list?type=${item.type}`);
    },
    filterCustoms(customsMap, item) {
      return get(customsMap, get(item, 'orderGlobal.customs'), '');
    },
    // 复制订单号
    onCopy(e) {
      this.$message.success('订单号' + e.text + '复制成功');
    },
    onCopyTransactionNo(e) {
      this.$message.success('交易单号' + e.text + '复制成功');
    },
    onErrorTransactionNo(e) {
      this.onError(e, '交易单号');
    },
    onError(e, text = '订单号') {
      // console.log(e);
      this.$alert(text + '复制失败', '请手动复制', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${text}`,
          });
        },
      });
    },
    // 获取订单来源数据字典
    fetchOrderSource() {
      this.sourceOptionsLoading = true;
      fetchOrderSourceOptions()
        .then(rs => {
          const res = rs.data.map(item => ({
            value: item.value,
            label: item.label,
          }));
          this.sourceOptions = res;
        })
        .finally(() => {
          this.sourceOptionsLoading = false;
        });
    },
    updateOrder() {
      this.fetchData();
    },
    refund(item, index) {
      const postData = {
        refundAmount: item.payAmount,
        tenantRefundNo: item.id,
        tenantTradeNo: item.id,
      };
      if (!this.refundLoading) {
        this.refundLoading = true;
        refund(postData)
          .then(response => {
            this.$message.success('退款成功');
          })
          .finally(() => {
            this.refundLoading = false;
          });
      }
    },
    deliveryGoods(item, index) {
      this.currentRow = index;
      this.orderConsigneeAddressVO = item.orderConsigneeAddressVO;
      this.tableData = item.orderDetailVOList;
      this.dialogFormVisible = true;
      this.currentOrderNos = item.orderNo;
      this.fetchLogistics(); // 获取弹出框中物流下拉
    },
    closeDialog() {
      // 弹出框的关闭事件，需要清空一下弹出框里面输入的数据
      this.commodityForm = {
        logistics: '', // 弹出框中选择的物流公司
        deliveryNo: '', // 弹出框中输入的快递单号
      };
    },
    delivery(formName) {
      // 弹出框里面的发货
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false;
        }
        this.btnLoading = true;
        const commodities = [];
        this.tableData.forEach((v, i) => {
          commodities.push({
            commodityCode: v.commodityCode,
            num: v.quantity,
          });
        });
        const logistics = this.commodityForm.logistics.split(',');
        const postData = {
          companyCode: logistics[0],
          companyName: logistics[1],
          commodities: commodities,
          deliveryNo: this.commodityForm.deliveryNo,
          orderNos: [this.currentOrderNos],
        };
        deliveryGoodsByHand(postData)
          .then(response => {
            this.$message.success('发货成功');
            // 刷新列表
            this.fetchData();
          })
          .finally(() => {
            this.dialogFormVisible = false;
            this.btnLoading = false;
            this.saveLoading = false;
          });
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    sureRefresh() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = pick(this, ['pageNo', 'pageSize', 'data', 'orderBy']);
      if (this.createDate) {
        listQuery.data.createBeginDate = this.createDate[0].getTime();
        listQuery.data.createEndDate = this.createDate[1].getTime();
      } else {
        delete listQuery.data.createBeginDate;
        delete listQuery.data.createEndDate;
      }
      list(listQuery)
        .then(response => {
          this.list = response.data.list;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    onExport(type) {
      const listQuery = this.data;
      if (this.createDate) {
        listQuery.createBeginDate = this.createDate[0].getTime();
        listQuery.createEndDate = this.createDate[1].getTime();
      } else {
        delete listQuery.data.createBeginDate;
        delete listQuery.data.createEndDate;
      }
      const len = Object.keys(listQuery).length;
      if (!len || len < 1) {
        this.$message({
          message: '请输入导出条件，再进行导出！！！',
          type: 'error',
        });
        return;
      }
      type === 'dropshipping' ? (this.exportLoading = true) : (this.exportLoadingNormal = true);
      const request = type === 'dropshipping' ? exportDropshipping : exportTable;
      request(listQuery)
        .then(res => {
          download(
            res,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `订单列表-${parseTime(Date.now(), '{y}-{m}-{d}-{h}:{i}:{s}')}.xlsx`
          );
        })
        .finally(() => {
          this.exportLoading = false;
          this.exportLoadingNormal = false;
        });
    },
    fetchStatus() {
      // 订单状态列表
      this.statusOptionsLoading = true;
      fetchOrderStatusOptions()
        .then(rs => {
          if (rs && rs.data) {
            const res = rs.data.map(item => ({
              value: item.value,
              label: item.label,
              sort: item.sort,
            }));
            this.statusOptions = res
              .filter(i => i.label !== '付款待确认')
              .sort((a, b) => a.sort - b.sort);
          }
        })
        .finally(() => {
          this.statusOptionsLoading = false;
        });
    },
    // 订单合同主体列表
    getOrderList() {
      this.orderOptionsLoading = true;
      listOrderContractInfo()
        .then(rs => {
          if (rs && rs.data) {
            const res = rs.data.map(item => ({
              value: item.id,
              label: item.merchantTypeName + '-' + item.contractInfoName,
            }));
            res.push({
              value: 'EMPTY',
              label: '无主体',
            });
            this.contractList = res;
          }
        })
        .finally(() => {
          this.orderOptionsLoading = false;
        });
    },
    fetchType() {
      // 创建方式列表
      this.typeOptionsLoading = true;
      fetchTypeOptions()
        .then(rs => {
          const res = rs.data.map(item => ({
            value: item.value,
            label: item.label,
          }));
          this.typeOptions = res;
        })
        .finally(() => {
          this.typeOptionsLoading = false;
        });
    },
    fetchOrderType() {
      // 订单类型列表
      this.orderTypeOptionsLoading = true;
      fetchOrderTypeOptions()
        .then(rs => {
          const res = rs.data.map(item => ({
            value: item.value,
            label: item.label,
          }));
          this.orderTypeOptions = res;
        })
        .finally(() => {
          this.orderTypeOptionsLoading = false;
        });
    },
    fetchLogistics() {
      // 弹出框里面的物流公司列表
      this.logisticsOptionsLoading = true;
      const data = {
        deliveryCompany: {},
      };
      fetchLogisticsOptions(data)
        .then(rs => {
          const res = rs.data.map(item => ({
            value: item.code,
            label: item.name,
          }));
          this.logisticsOptions = res;
        })
        .finally(() => {
          this.logisticsOptionsLoading = false;
        });
    },
    onSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.fetchData();
    },
    onReset() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.createDate = null;
      this.filter = cloneDeep(this.initFilter);
      this.createDate = this.initDate();
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    // 修改价格
    modifyPrice(item, index) {
      const AmendData = cloneDeep(item);
      AmendData.orderDetailVOList = item.orderDetailVOList.filter(item => item.isGift === '0');
      this.AmendData = AmendData;
      this.dialogAmendVisible = true;
    },
    // 点击添加备注
    handleAddNotes(item) {
      const { serviceNotes, id } = item;
      serviceNotes ? (this.serviceNotes = serviceNotes) : (this.serviceNotes = '');
      this.dialogVisible = true;
      this.serviceNotesId = id;
    },
    isPrimaryBtn(item, type) {
      if (
        item.status === 'DELIVERED' ||
        item.status === 'SUB_DELIVERY' ||
        item.status === 'RECEIVED' ||
        item.status === 'FINISHED' ||
        item.status === 'REFUND'
      ) {
        return type === 'wl' ? 'primary' : '';
      }
      if (item.status === 'WAIT_PAY') {
        return type === 'fk' ? 'primary' : '';
      }
      if (
        item.status !== 'WAIT_PAY' &&
        item.orderSource !== 'SAMPLE' &&
        item.orderSource !== 'SPECIAL_BUY'
      ) {
        return type === 'gwc' ? 'primary' : '';
      }
    },
    addNotes() {
      const postData = {
        id: this.serviceNotesId,
        serviceNotes: this.serviceNotes,
      };
      addServiceNotes(postData).then(res => {
        this.fetchData();
        this.dialogVisible = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      });
    },
    // 获取品牌列表
    fetchBrandListAll() {
      this.brandOptionsLoading = true;
      brandListAll()
        .then(res => {
          this.brandList = res.data || [];
        })
        .finally(() => {
          this.brandOptionsLoading = false;
        });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import 'src/styles/goods-table.scss';
.order-list-explain {
  color: #e98e5e;
  background: #fffaf1;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
}
.flag {
  width: 20px;
  cursor: pointer;
}
.dialog-goods-img {
  display: block;
  width: 60px;
  height: 60px;
}
.dialog-section {
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  .title {
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    padding: 18px 30px;
    font-size: 16px;
    user-select: none;
  }
  .text-container {
    display: flex;
    .text {
      display: flex;
      width: 50%;
      box-sizing: border-box;
      margin-bottom: 16px;
      min-width: 334px;
      text-align: right;
      .label {
        padding-right: 16px;
        white-space: nowrap;
        display: inline-block;
        line-height: 32px;
      }
      .content {
        flex: 1 1;
      }
    }
  }
  .text {
    padding: 18px 30px;
    font-size: 14px;
    &.second {
      padding-top: 0;
    }
    &.p0 {
      padding-bottom: 0;
    }
  }
}
.dialog-footer {
  padding: 10px 20px;
}
.filter-list {
  margin-bottom: 24px;
}
.failure {
  color: red;
}

.goods-name {
  .name-r {
    padding-left: 54px;
  }
}
.date-picker {
  width: 260px;
}
.filter-item-btn {
  justify-content: space-between;
}

.btns-open {
  // width: 66.6% !important;
  padding-right: 0 !important;
}

.linknone:hover {
  text-decoration: underline;
}
.order-list--btn {
  &.el-button--primary:hover,
  &.el-button--primary:focus {
    background: #df3a59;
    border-color: #df3a59;
    color: #ffffff;
  }
  &.el-button--primary {
    color: #ffffff;
    background-color: #ab0033;
    border-color: #ab0033;
  }
  border: 1px solid #ab0033;
  color: #ab0033;
}
.refund-list .table-body .line td.tips-content {
  padding: 0 12px;
}
</style>
